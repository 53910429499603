import React, {useState, useEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import HistoryThief from "./components/utils/HistoryThief";
import {useAuth} from "./utils/authContext";

import {NotificationContainer, NotificationManager} from "react-notifications";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Header from "./components/Header";
import Langs from "./pages/Langs";
import Phrases from "./pages/Phrases";
import Users from "./pages/Users";
import UserDetail from "./pages/UserDetail";
import Stats from "./pages/Stats";
import Broadcast from "./pages/Broadcast";

import './App.css';


function App() {
    let auth = useAuth();

    useEffect(() => {}, [auth.user])

    return <BrowserRouter>
        <HistoryThief />
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/*"
                   element={auth.user && <>
                       <Header is_superuser={auth.user.is_superuser} key="header"/>
                       <div className='mainContentLayout'>
                           <Routes>
                               <Route path='/langs' element={<Langs />}/>
                               <Route path='/langs/:langId/phrases' element={<Phrases />}/>
                               <Route path='/' element={<Home />}/>
                               {
                                   auth.user.is_superuser &&
                                   <Route path='/*' element={
                                       <Routes>
                                           <Route path='/users/:userId' element={<UserDetail/>}/>
                                           <Route path='/users' element={<Users/>}/>
                                           <Route path='/stats' element={<Stats/>}/>
                                           <Route path='/broadcast' element={<Broadcast/>}/>
                                       </Routes>
                                   }/>
                               }
                           </Routes>
                       </div>
                       <NotificationContainer/>
                   </>
                   }
            />
        </Routes>
    </BrowserRouter>
}

export default App;
