import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import api from "../../utils/api";
import Loading from "../../components/Loading";
import './userDetail.css'
import rawApi from "../../utils/rawApi";
import {NotificationManager} from "react-notifications";
import historyRef from "../../utils/history";

const UserDetail = () => {
    const {userId} = useParams()
    const isNew = (userId === 'null')
    const [user, setUser] = useState({});

    useEffect(() => {
        if (!isNew)
            api(`/users/${userId}`).then((result) => {
                setUser(result)
            })
    }, []);

    const onDelete = () => {
        if (isNew) {
            NotificationManager.success(``, 'Пользователь удалён', 5000)
            historyRef.navigate('/users')
        } else {
            rawApi(
                `/users/${userId}`, {method: 'DELETE'}
            ).then((result) => {
                if (result.status >= 300)
                    NotificationManager.error(`Ошибка #${result.status}`, 'Пользователь не удалён', 5000)
                else {
                    NotificationManager.success(``, 'Пользователь удалён', 5000)
                    historyRef.navigate('/users')
                }
            }).catch((err) => {
                NotificationManager.error(`Ошибка: ${err}`, 'Пользователь не удалён', 5000)
            })
        }
    }

    const onSubmit = () => {
        let json = {
            username: user.username,
            email: user.email,
            tg_account_id: user.tg_account_id,
            is_support: user.is_support,
            is_superuser: user.is_superuser,
        }
        if (isNew)
            json = {...json, password: user.password}
        const method = isNew ? 'POST' : 'PATCH'
        const url = isNew ? '/auth/register' : `/users/${userId}`
        rawApi(
            url, {method: method, json: json}
        ).then((result) => {
            if (result.status >= 300)
                NotificationManager.error(`Ошибка #${result.status}`, 'Данные не сохранены', 5000)
            else {
                NotificationManager.success(``, 'Данные сохранены', 5000)
                historyRef.navigate('/users')
            }
        }).catch((err) => {
            NotificationManager.error(`Ошибка: ${err}`, 'Данные не сохранены', 5000)
        })
    }

    return <div className='userDetailPage'>
        {
            (user !== {} || !isNew)
                ? <form className='userDetailForm'>
                    <div className='inputBoxWrapper'>
                        <div className='inputbox'>
                            <input type="text"
                                   placeholder='Username'
                                   onChange={e => setUser({...user, username: e.target.value})}
                                   value={user.username}
                                   required="required"
                            />
                        </div>
                        <div className='inputbox'>
                            <input type="email"
                                   placeholder='Email'
                                   onChange={e => setUser({...user, email: e.target.value})}
                                   value={user.email}
                                   required="required"
                            />
                        </div>
                        {
                            (isNew) && <div className='inputbox'>
                                <input type="password"
                                       placeholder='Пароль'
                                       onChange={e => setUser({...user, password: e.target.value})}
                                       value={user.password}
                                       required="required"
                                />
                            </div>
                        }
                        <div className='withLabel'>
                            <label className='userLabel'>Администратор</label>
                            <input type="checkbox"
                                   className="userDetailCheckbox"
                                   onChange={e => setUser({...user, is_superuser: e.target.checked})}
                                   checked={user.is_superuser}
                                   required="required"
                            />
                        </div>
                    </div>

                    <div onClick={onSubmit} className="userDetailSubmit">
                        Сохранить
                    </div>
                    {
                        !isNew
                        && <div onClick={onDelete} className="userDetailDelete">
                            Удалить
                        </div>
                    }
                </form>
                : <Loading />
        }
    </div>
}


export default UserDetail