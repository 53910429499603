import React, {useRef} from "react";
import {Button} from '../Messages'
import SvgWrap from "../../components/SvgWrap";

import touchFile from '../../images/touchFiles.svg'
import './style.css'


const Uploader = ({setFile}) => {
    const beforeUpload = (_) => false;

    const hiddenFileInput = useRef(null);

    const handleClick = (_) => hiddenFileInput.current.click();

    const onChangeUpload = (event) => {
        let fileObj = event.target.files[0]
        if (fileObj === undefined)
            setFile(null)
        else
            setFile(fileObj)
    }

    return <>
        <Button text={<SvgWrap image={touchFile} className='filesIcon'/>}
                className='fileBtn'
                onClick={handleClick}
        />
        <input type="file"
               id="file"
               name="file"
               style={{display: 'none'}}
               ref={hiddenFileInput}
               onChange={onChangeUpload}
               onBeforeInput={beforeUpload}
               onBeforeInputCapture={beforeUpload}
        />
    </>;
};

export default Uploader