import React, {useState} from "react";
import historyRef from "../../utils/history";
import {Button, List, SwipeAction, TextArea} from "antd-mobile";
import api from "../../utils/api";
import {NotificationManager} from "react-notifications";
import SvgWrap from "../../components/SvgWrap";
import saveIcon from "../../images/save-icon.svg";


const Lang = ({id, name, onDelete, onSave}) => {
    const [value, setValue] = useState(name)

    const leftActions = [{
        text: 'Открыть фразы',
        color: 'primary',
        onClick: () => {historyRef.navigate(`/langs/${id}/phrases`)}
    }]
    const rightActions = [{
        text: 'Удалить',
        color: 'danger',
        onClick: () => {
            if (id === null){
                onDelete(id)
            } else if (id !== 1) {
                api(
                    `/langs/langs/${(id !== null) ? id : ""}`, {method: 'DELETE'}
                ).then(() => {
                    NotificationManager.success('', 'Удалено', 5000)
                    onDelete(id)
                }).catch((err) => {
                    NotificationManager.error(`${err}`, 'Ошибка', 5000)
                })
            } else {
                NotificationManager.error(`Нельзя удалить основной язык`, 'Ошибка', 5000)
            }
        }
    }]

    return <SwipeAction
        className='listItem'
        key={id}
        leftActions={leftActions}
        rightActions={rightActions}
    >
        <List.Item className='listItem' extra={
            <Button color='success'
                    onClick={() => {
                        api(
                            `/langs/langs/${(id !== null) ? id : ""}`,
                            {method: (id !== null) ? 'PUT' : 'POST', json: {name: value}}
                        ).then(() => {
                            NotificationManager.success('', 'Сохранено', 5000)
                            onSave()
                        }).catch((err) => {
                            NotificationManager.error(`${err}`, 'Ошибка', 5000)
                        })
                    }}
            >
                <SvgWrap image={saveIcon} className='svgButton'/>
            </Button>
        }>
            <TextArea value={value}
                      className='listItem'
                      onChange={(newValue) => setValue(newValue)}
            />
        </List.Item>
    </SwipeAction>
}


export default Lang