import React from "react";
import historyRef from "../../utils/history";


const UserCard = ({id, email, username, is_superuser}) => {
    return <div className='userCard'>
        <div>
            <div className='userCardLabel'>
                {email}
            </div>
            <div className='userCardLabel'>
                {username}
            </div>
            {is_superuser && <div className='userCardLabel'>Администратор</div>}
        </div>
        <button className="buttonWithHover"
                onClick={() => {historyRef.navigate(`/users/${id}`)}}
        >
            Редактировать
        </button>
    </div>
}


export default UserCard