import React, {useEffect, useState} from "react";
import api from "../../utils/api";
import {Button, List} from "antd-mobile";


const History = () => {
    const [time, setTime] = useState(Date.now());
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(0);
    const [countMaxItem, setCountMaxItem] = useState(0);
    const onPage = 20;

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        api(`/broadcast?limit=${onPage}&offset=${page * onPage}`).then(
            (resp) => {
                setCountMaxItem(resp.count);
                setMessages(resp.items)
            }
        )
    }, [time, page])

    return <div>
        <List header={`Обновлено: ${time.toLocaleString()}`}>
            {messages.map((item) => {
                return <List.Item>
                    <div>ID: {item.id}</div>
                    <div>Текст: {item.text}</div>
                    <div>Создано: {item.created_at_formatted}</div>
                    <div>Запланировано на: {item.on_time_formatted}</div>
                    <div>
                        Каналы рассылки:
                        <div>
                            {item.networks.map((network) => {
                                return <div className='networks-list-item'>
                                    <div>Канал: {network.network}</div>
                                    <div>Обработано: {network.count_process}</div>
                                    <div>Успешно: {network.count_success}</div>
                                    <div>Всего: {network.count_all}</div>
                                    <div>Завершено в: {network.finished_at_formatted}</div>
                                </div>
                            })}
                        </div>
                    </div>

                </List.Item>
            })}
        </List>
        <div className='buttons-row'>
            <Button disabled={page === 0}
                    onClick={() => setPage(page - 1)}
                    color='primary'
                    size='large'
            >
                Назад
            </Button>
            <div>Страница {page + 1} / {Math.floor(countMaxItem / onPage) + 1}</div>
            <Button disabled={page * onPage >= countMaxItem}
                    onClick={() => setPage(page + 1)}
                    color='primary'
                    size='large'
            >
                Вперёд
            </Button>
        </div>
    </div>
}


export default History