import React, {useEffect, useState} from "react";
import api from "../../utils/api";
import Loading from "../../components/Loading";
import {List, Search} from "antd-mobile";
import Phrase from "./Phrase";
import {useParams} from "react-router-dom";
import './phrases.css'


const Phrases = () => {
    const [phrases, setPhrases] = useState(null)
    const [codeFilter, setCodeFilter] = useState('')

    const {langId} = useParams()

    useEffect(() => {
        api(`/langs/phrases/?lang_id=${langId}`).then((result) => {
            setPhrases(result)
        })
    }, [langId])

    return (phrases === null)
        ? <Loading />
        : <div className='phrasesList'>
            <Search className='forAllScreen'
                    value={codeFilter}
                    onChange={(value) => setCodeFilter(value)}
            />
            <List>
                {phrases.filter((item) => item.code.includes(codeFilter)).map((item) => (
                    <Phrase key={item.id} id={item.id} code={item.code} text={item.text}/>
                ))}
            </List>
        </div>
}


export default Phrases