import React, {useState} from "react";
import {TextArea, List, Button} from "antd-mobile";
import api from "../../utils/api";
import {NotificationManager} from "react-notifications";
import saveIcon from "../../images/save-icon.svg";
import SvgWrap from "../../components/SvgWrap";


const Phrase = ({id, code, text}) => {
    const [value, setValue] = useState(text)

    return <List.Item key={id} title={code} className='listItem' extra={
        <Button color='success'
                onClick={() => {
                    api(
                        `/langs/phrases/${id}`,
                        {method: 'PUT', json: {text: value}}
                    ).then(() => {
                        NotificationManager.success('', 'Сохранено', 5000)
                    }).catch((err) => {
                        NotificationManager.error(`${err}`, 'Ошибка', 5000)
                    })
                }}
        >
            <SvgWrap image={saveIcon} className='svgButton'/>
        </Button>
    }>
        <TextArea value={value}
                  onChange={(newValue) => setValue(newValue)}
                  autoSize
        />
    </List.Item>
}


export default Phrase