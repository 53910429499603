import React, {useEffect, useState} from "react";
import {Button, DatePicker, Divider, TextArea} from "antd-mobile";
import api from "../../utils/api";
import {NotificationManager} from "react-notifications";
import Uploader from "../../components/Uploader";
import SvgWrap from "../../components/SvgWrap";

import removeFileIcon from "../../images/file.svg";
import DropDown from "../../components/DropDown/DropDown";
import History from "./History";

import '../UserDetail/userDetail.css'
import './style.css'


const DEFAULT_BROADCAST = {
    "text": "",
    "networks": ["telegram"],
    "on_time": null
}


const Broadcast = () => {
    const [data, setData] = useState(DEFAULT_BROADCAST)
    const [inputFile, setInputFile] = useState(null)
    const [imgError, setImgError] = useState(false)
    const [visible, setVisible] = useState(false)

    const onSend = () => {
        const sendMessage = (file_id) => {
            api(
            `/broadcast`,
            {
                method: 'POST',
                json: {
                    ...data,
                    file_id: file_id,
                    on_time: data.on_time ? data.on_time.toISOString() : null
                }
            }).then(
                (response) => {
                    setInputFile(null);
                    setImgError(null);
                    setData(DEFAULT_BROADCAST)
                    NotificationManager.success('Рассылка успешно запущена')
                }
            ).catch((err) => NotificationManager.error(`Ошибка: ${err}`))
        }

        if (inputFile !== null) {
            const formData = new FormData();
            formData.append('file', inputFile)

            api(`/broadcast/file`, {method: 'POST', body: formData}).then(
                (savedFile) => {sendMessage(savedFile.id)}
            ).catch((err) => NotificationManager.error(`Ошибка: ${err}`))
        } else {
            sendMessage(null)
        }
    }

    return <div className='broadcastWrapper'>
        <DropDown title='Создание рассылки' children={
            <>
                <div className='withLabel'>
                    <label className='userLabel'>Текст</label>
                    <TextArea className='broadcastInput'
                              placeholder='Введите текст сообщения'
                              onChange={value => setData({...data, text: value})}
                              value={data.text}
                              autoSize={true}
                    />
                </div>
                <div className='withLabel'>
                    <label className='userLabel'>Файл</label>
                    <Uploader setFile={setInputFile}/>
                        <div onClick={() => setInputFile(null)}>
                            {
                                (inputFile !== null)
                                    ? (imgError)
                                        ? <SvgWrap image={removeFileIcon} className='removeFileIcon'/>
                                        : <img src={URL.createObjectURL(inputFile)}
                                               alt='FILE'
                                               className='inputChatFilePreview'
                                               onError={() => setImgError(true)}
                                        />
                                    : <></>
                            }
                        </div>
                </div>
                <div className='withLabel'>
                    <label className='userLabel'>Время рассылки</label>
                    <Button onClick={() => {setVisible(true)}}
                            style={{color: 'black'}}
                    >
                        {data.on_time ? data.on_time.toLocaleString() : 'Мгновенная отправка'}
                    </Button>
                    <DatePicker
                        tillNow
                        visible={visible}
                        onClose={() => {setVisible(false)}}
                        defaultValue={new Date()}
                        precision='minute'
                        onConfirm={val => {
                            setData({
                                ...data,
                                on_time: val
                            })
                        }}
                    />
                </div>
                <button className='btnWithoutFill' onClick={onSend}>
                    Отправить всем
                </button>
            </>
        }/>
        <Divider />
        <History />
    </div>
}


export default Broadcast