import React, {useState} from "react";
import 'react-notifications/lib/notifications.css';
import SvgWrap from "../../components/SvgWrap";

import logo from "../../images/logo.svg"
import login_btn from "../../images/login/login_btn.svg"
import './login.css'
import {useAuth} from "../../utils/authContext";


const Login = () => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    let auth = useAuth();

    const onSubmit = () => auth.signIn(username, password);

    return <div className='loginPage'>
        <form className='loginPage_form'>
            <SvgWrap image={logo}/>
            <div className='inputbox'>
                <input type="text"
                       placeholder='Email'
                       onChange={e => setUsername(e.target.value)}
                       value={username}
                       required="required"
                />
            </div>
            <div className='inputbox'>
                <input type="password"
                       placeholder='Пароль'
                       onChange={e => setPassword(e.target.value)}
                       value={password}
                       required="required"
                />
            </div>

            <div onClick={onSubmit} className="loginFormSubmit">
                <SvgWrap image={login_btn}/> Войти
            </div>
        </form>
    </div>
}

export default Login