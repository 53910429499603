import React, {useState} from "react";
import {DownOutline, UpOutline} from "antd-mobile-icons";
import './style.css'


const DropDown = ({title, children}) => {
    const [isVisible, setIsVisible] = useState(false)

    const onCLick = () => setIsVisible(!isVisible);

    return <div className='drop-down-box'>
        <div className='drop-down-title'>
            {
                (isVisible) ? <DownOutline onClick={onCLick}/> : <UpOutline onClick={onCLick}/>
            }
            <div className='drop-down-title-text'>
                {title}
            </div>
        </div>
        <div className='drop-down-children' style={{display: (isVisible) ? 'block' : 'none'}}>
            {children}
        </div>
    </div>
}


export default DropDown
