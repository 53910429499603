import React, { Component } from 'react';
import { format } from'timeago.js';
import classNames from 'classnames';
import './style.css';


export class ChatItem extends Component {

    constructor(p) {
        super(p);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick(e) {
        e.preventDefault();
        this.props.onClick();
    }

    render() {
        return (
            <div className={classNames('rce-container-citem', this.props.className)}>
                <div className="rce-citem">
                    <div className="rce-citem-body"
                         onClick={this.handleOnClick}
                    >
                        <div className="rce-citem-body--top">
                            <div className="rce-citem-body--top-title">
                                {this.props.title}
                            </div>
                            <div className="rce-citem-body--top-time">
                                {
                                    this.props.date &&
                                    !isNaN(this.props.date) &&
                                    (
                                        this.props.dateString ||
                                        format(this.props.date)
                                    )
                                }
                            </div>
                        </div>

                        <div className="rce-citem-body--bottom">
                            <div className="rce-citem-body--bottom-title">
                                {this.props.subtitle}
                            </div>
                            <div className="rce-citem-body--bottom-status">
                                {
                                    this.props.unread > 0 &&
                                    <span>{this.props.unread}</span>
                                }
                            </div>
                        </div>
                    </div>
                    {   (this.props.onStopChat != null) &&
                        <button className='btnWithoutFill'
                                 onClick={this.props.onStopChat}
                        >
                            Завершить
                        </button>
                    }
                </div>
            </div>
        );
    }
}

ChatItem.defaultProps = {
    id: '',
    onClick: null,
    title: '',
    subtitle: '',
    date: null,
    unread: 0,
    statusColor: null,
    statusColorType: 'badge',
    statusText: null,
    dateString: null,
}

export default ChatItem;