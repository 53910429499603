import React from "react";


const StatsCard = ({title, value, sub=""}) => {
    return <div className='statsCard'>
        <div className='statsCardTitle'>
            {title}
        </div>
        <div className='statsCardValue'>
            {value} {sub}
        </div>
    </div>
}


export default StatsCard