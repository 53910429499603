import React, {useState, useEffect, createRef,} from 'react';
import MessageBox from '../MessageBox';
import {FaChevronDown} from 'react-icons/fa';
import './style.css';

const classNames = require('classnames');

const MessageList = (props) => {
    const [canBottom, setCanBottom] = useState(false)

    const bottomRef = createRef();
    const divRef = createRef();

    useEffect(() => {
        setCanBottom(true)
    }, [props.dataSource.length])

    const onOpen = (item, i, e) => {
        if (props.onOpen instanceof Function)
            props.onOpen(item, i, e);
    }

    const onDownload = (item, i, e) => {
        if (props.onDownload instanceof Function)
            props.onDownload(item, i, e);
    }

    const onClick = (item, i, e) => {
        if (props.onClick instanceof Function)
            props.onClick(item, i, e);
    }

    const onMessageFocused = (item, i, e) => {
        if (props.onMessageFocused instanceof Function)
            props.onMessageFocused(item, i, e);
    }

    const toBottom = () => {
        bottomRef.current.scrollIntoView({behavior: "smooth"})
        setCanBottom(false);
    }

    const onScroll = () => {
        // console.log(bottomRef.current.offsetTop)
        // console.log(divRef.current.scrollTop)
    }

    return (
        <div className={classNames(['rce-container-mlist', props.className])}>
            <div className='rce-mlist'
                 onScroll={onScroll}
                 ref={divRef}
            >
                {
                    props.dataSource.map((x, i) => (
                        <MessageBox
                            key={i}
                            {...x}
                            onOpen={props.onOpen && ((e) => onOpen(x, i, e))}
                            onDownload={props.onDownload && ((e) => onDownload(x, i, e))}
                            onClick={props.onClick && ((e) => onClick(x, i, e))}
                            onMessageFocused={props.onMessageFocused && ((e) => onMessageFocused(x, i, e))}
                            onMeetingTitleClick={props.onMeetingTitleClick}
                            onMeetingVideoLinkClick={props.onMeetingVideoLinkClick}
                        />
                    ))
                }
                <div ref={bottomRef}/>
            </div>
            {
                <div className='rce-mlist-down-button'
                     onClick={toBottom}
                >
                    <FaChevronDown/>
                    {
                        canBottom && <span className='rce-mlist-down-button--badge'/>
                    }
                </div>
            }
        </div>
    );
}

MessageList.defaultProps = {
    onClick: null,
    onTitleClick: null,
    onForwardClick: null,
    onReplyClick: null,
    onReplyMessageClick: null,
    onMeetingMessageClick: null,
    onDownButtonClick: null,
    dataSource: [],
    lockable: false,
    toBottomHeight: 300,
    downButton: true,
    downButtonBadge: null,
};

export default MessageList;