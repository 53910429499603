import React, {useEffect, useState} from "react";
import api from "../../utils/api";
import {CgSpinner} from "react-icons/all";
import DropDown from "../../components/DropDown/DropDown";
import './style.css'



const Home = () => {
    const [data, setData] = useState(null)
    useEffect(() => {
        api(`/stats`).then((result) => setData(result))
    }, [])

    return (data === null) ? <CgSpinner className='home-page'/> : <div className='home-page'>
        <DropDown title='Общая информация' children={<>
            {data?.items.map((item) =>
                <div className='drop-down-children-row'>
                    {item.name}: {item.value}
                </div>
            )}
        </>}/>

    </div>
}


export default Home