import React, {useState, useEffect} from "react";

import {Button, List, SwipeAction} from 'antd-mobile'
import api from "../../utils/api";
import Loading from "../../components/Loading";

import './langs.css'

import Lang from "./Lang";


const Langs = () => {
    const [languages, setLanguages] = useState(null)

    const update = () => {
        api('/langs/langs/').then((result) => {
            setLanguages(result)
        })
    }

    useEffect(() => {
        update()
    }, [])

    const onDelete = (id) => {
        const oldLangs = [...languages]
        const deletedIndex = oldLangs.findIndex((item, index) => item.id === id)
        setLanguages([
            ...oldLangs.slice(0, deletedIndex),
            ...oldLangs.slice(deletedIndex+1)
        ])
    }

    return (languages === null)
        ? <Loading/>
        : <div className='langsWrapper'>
            <button className='btnWithoutFill block'
                    color='primary'
                    onClick={() => {
                        setLanguages([...languages, {id: null, name: null}])
                    }}
            >
                Добавить язык
            </button>
            <List className='listWithData'>
                {languages.map((lang) => (
                    <Lang id={lang.id} name={lang.name} onDelete={onDelete} onSave={update}/>
                ))}
            </List>
        </div>
}


export default Langs