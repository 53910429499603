import React, { Component } from 'react';
import './style.css';

const classNames = require('classnames');

export class ChatList extends Component {
    onClick(item, i, e) {
        if (this.props.onClick instanceof Function)
            this.props.onClick(item, i, e);
    }

    onContextMenu(item, i, e) {
        e.preventDefault();
        if (this.props.onContextMenu instanceof Function)
            this.props.onContextMenu(item, i, e);
    }

    render() {
        return (
            <div
                className={classNames('rce-container-clist', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

ChatList.defaultProps = {
    children: [],
    onClick: null,
};

export default ChatList;