import React, {useState, useEffect} from "react";
import api from "../../utils/api";
import Loading from "../../components/Loading";
import StatsCard from "./StatsCard";
import Select from 'react-select';
import _ from 'lodash';
import './stats.css'
import DateRangePicker from "../../components/DateRangePicker";


const Stats = () => {
    const [stats, setStats] = useState(null)

    const [moderators, setModerators] = useState([])

    useEffect(() => {
        api('/users/minimize').then((result) => {setModerators(result)})
        loadStats()
    }, [])

    const [filters, setFilters] = useState({
        time_gte: null,
        time_lte: null,
        support_id: null,
    })

    const loadStats = () => {
        api('/stats/', {
            searchParams: _.omitBy({
                ...filters,
                time_gte: (filters.time_gte !== null) ? filters.time_gte.toISOString() : null,
                time_lte: (filters.time_lte !== null) ? filters.time_lte.toISOString() : null,
            }, _.isNull)
        }).then((result) => { setStats(result) })
    }

    useEffect(() => {
        api('/users/minimize/').then((result) => {setModerators(result)})
        loadStats()
    }, [])

    return <div className='statsWrapper'>
        <div className='statsFilters'>
            <Select className="statsSelect"
                    placeholder='Модератор'
                    defaultValue={null}
                    isSearchable
                    onChange={(item) => {setFilters({...filters, support_id: item.value})}}
                    options={[{label: 'Не выбрано', value: null}, ...moderators.map((item) => {
                        return {label: `${item.username}`, value: item.id}
                    })]}
            />
            <DateRangePicker className='datetimePicker'
                             onStartDateChange={(value) => setFilters({...filters, time_gte: value})}
                             onEndDateChange={(value) => setFilters({...filters, time_lte: value})}
            />
            <button className='btnFilters'
                    onClick={loadStats}
            >
                Отфильтровать
            </button>
        </div>
        {
            (stats === null)
                ? <Loading />
                : <>
                    <StatsCard title={'Пользователей всего'} value={stats.count_users} />
                    <StatsCard title={'Продолжительность чата с поддержкой'} value={stats.chat_duration} sub='c.'/>
                    <StatsCard title={'Время до ответа оператора'} value={stats.answer_duration} sub='c.'/>
                    <StatsCard title={'Обращений в поддержку'} value={stats.count_chats} />
                </>
        }
    </div>
}


export default Stats