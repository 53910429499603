import React from "react";
import historyRef from "../../utils/history";


const UserCardCreate = () => {
    return <div className='userCard userCardCreate'>
        <button className="buttonWithHover"
                onClick={() => {
                    historyRef.navigate(`/users/null`)
                }}
        >
            Создать
        </button>
    </div>
}


export default UserCardCreate