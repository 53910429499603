import React, {useState} from "react";
import { slide as Menu } from "react-burger-menu";
import {Link} from "react-router-dom";

import './header.css'


const Header = ({is_superuser}) => {
    const [links, setLinks] = useState(is_superuser ? [
        <Link to="/users" key={1}>
            Модераторы
        </Link>,
        <Link to="/broadcast" key={3}>
            Рассылка
        </Link>,
    ] : <></>)

    return <div className='header'>
        <Menu>
            <Link to="/" key={5}>
                Главная
            </Link>
            <Link to="/langs" key={6}>
                Языки
            </Link>
            {links}
        </Menu>
    </div>
}



export default Header