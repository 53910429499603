import React, {useState, useEffect} from "react";
import {useAuth} from "../../utils/authContext";
import api from "../../utils/api";
import UserCard from "./UserCard";

import './users.css'
import UserCardCreate from "./UserCardCreate";


const Users = () => {
    let auth = useAuth();

    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        api('/users/minimize').then((result) => {
            setUsersList(result)
        })
    }, []);

    return <div className='usersListWrapper'>
        {usersList.map((item) => <UserCard id={item.id}
                                           key={item.id}
                                           username={item.username}
                                           email={item.email}
                                           is_superuser={item.is_superuser}
        />)}
        <UserCardCreate />
    </div>
}


export default Users